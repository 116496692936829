<template>
  <div>
    <Form :options="formOptions"></Form>
  </div>
</template>
<script>
import moment from "moment";
import Form from "../../../../src/components/form/Form.vue";
import axios from '@/components/axios';
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  data() {
    return {
      formOptions: {
        submitRouterPath: "/setup/company",
        getEditValue: `${baseApi}/getCompanyById`,
        gridForm: true,
        method: "post",
        action: "add",
        url: `${baseApi}/company`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Company Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Name",
            class: "col-md-4",
            required: true,
          },
          {
            label: "City",
            name: "city",
            value: "",
            type: "text",
            placeholder: "Enter city",
            class: "col-md-4",
            required: true,
          },
          {
            label: "State",
            name: "state",
            value: "",
            type: "text",
            placeholder: "Enter state",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Country",
            name: "country",
            value: "",
            type: "text",
            placeholder: "Enter Country",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Pin Code",
            name: "pincode",
            value: "",
            type: "number",
            placeholder: "Enter Pincode",
            class: "col-md-4",
            required: true,
            rules: "digits:6",
          },
          {
            label: "Financial Year",
            name: "financialyear",
            value: "",
            type: "number",
            placeholder: "Enter Financial Year",
            class: "col-md-4",
            required: true,
          },
          {
            label: "GST No",
            name: "gstno",
            value: "",
            type: "number",
            placeholder: "Enter GST No",
            class: "col-md-4",
            required: true,
          },
          {
            label: "CST No",
            name: "cstno",
            value: "",
            type: "number",
            placeholder: "Enter CST No",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Pan No",
            name: "panno",
            value: "",
            type: "text",
            placeholder: "Enter Pan No",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Service Tax Number",
            name: "servicetaxnumber",
            value: "",
            type: "number",
            placeholder: "Enter Service Tax Number",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Phone No",
            name: "phone",
            value: "",
            type: "number",
            placeholder: "Enter Phone No",
            class: "col-md-4",
            required: true,
            rules: "digits:10",
          },
          {
            label: "Email",
            name: "email",
            value: "",
            type: "email",
            placeholder: "Enter Email",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Website",
            name: "website",
            value: "",
            type: "text",
            placeholder: "Enter Website",
            class: "col-md-4",
            required: true,
          },
          {
            label: "Logo",
            name: "logo",
            value: "",
            type: "fileinput",
            placeholder: "Select Logo",
            class: "col-md-4",
            required: true,
          },
        ],
      },
    };
  },
  components: {
    Form,
  },
  mounted() {
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      this.$router.push("/");
    }
  },
  beforeMount() {
    const accessToken = localStorage.getItem("accessToken");
    const baseApi = process.env.VUE_APP_APIENDPOINT;
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == "dropdown" && item.url) {
        const requestOptionsUsers = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          url: `${baseApi}/${item.url}`,
        };

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data);
          })
          .catch((error) => console.log(error, "error"));
      }
    });
  },
  mounted() {},
};
</script>
